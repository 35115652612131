import { useEffect, useState } from 'react';

const GEOCODING_URL =
  'https://api.bigdatacloud.net/data/reverse-geocode-client';

const useLocation = () => {
  const [location, setLocation] = useState({
    country: '',
    city: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const loadLocation = () => {
    const expiration = localStorage.getItem('location_expiration');
    if (!expiration) return null;
    const now = new Date();
    const expirationTime = new Date(expiration);
    if (expirationTime < now) return null;
    const location = localStorage.getItem('location');
    if (!location) return null;
    return JSON.parse(location);
  };

  const saveLocation = (location) => {
    const minutes = 1000 * 60;
    const now = new Date();
    const expirationTime = new Date(now.getTime() + 30 * minutes);
    localStorage.setItem('location_expiration', expirationTime.toString());
    localStorage.setItem('location', JSON.stringify(location));
  };

  useEffect(() => {
    const fetchLocation = () => {
      fetch(GEOCODING_URL)
        .then((res) => res.json())
        .then((data) => {
          const location = {
            country: data.countryName ?? '',
            city: data.locality ?? '',
          };
          setLocation(location);
          saveLocation(location);
          setIsLoading(false);
        });
    };

    const location = loadLocation();
    if (location) {
      setLocation(location);
      setIsLoading(false);
    } else {
      fetchLocation();
    }
  }, []);

  return { ...location, isLoading };
};

export default useLocation;
